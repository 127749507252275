/* eslint-disable global-require */
import React, { useState, useContext } from 'react';
import { PageContext } from '@/context/PageContext';
import { Helmet } from 'react-helmet';

import { ImportantInformation } from '@latitude/important-information';
import { BUTTON_STYLE, COLOR } from '@latitude/core/utils/constants';
import Section from '@latitude/section';
import ListNumbered from '@latitude/list-numbered/ListNumbered';
import { TabNavigation } from '@latitude/tab-navigation';
import { Link } from '@latitude/link';

import Layout from '@/components/layout';
import HeroBanner from '@/components/lab-components/HeroBanner';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

import ExpediaWotifSection from './_expedia-wotif';
import WhyChooseSection from './_why-choose';
import HaveAQuestionSection from './_have-a-question';
import imgHeroTravelPartners from './images/lframe-travel-partners.webp';
import DontHaveACardSection from '../credit-cards/28-degrees/_dont-have-card';

function TravelPartnersPage({ location }) {
  const [activeLinkId, setActiveLinkId] = useState('#booking');
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};


  function onTabClickHandler(linkId) {
    setActiveLinkId(linkId);
  }

  return (
    <Layout location={location}>
      <Helmet>
        <title>
          Travel Partners | Latitude 28&deg; Global Platinum Mastercard |
          International Credit Card
        </title>
      </Helmet>
      <main className="navigation-spacer">
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title={
              <>
                Escape the grind.
                <br />
                Escape paying full price
              </>
            }
            subTitle={<>Enjoy great savings with our travel partners.</>}
            pageImage={imgHeroTravelPartners}
            pageImageAlt="Travel Partners"
          />
        )}

        <ExpediaWotifSection
          anchor="expedia-wotif"
          heading="Get more from your getaway"
        />

        <Section
          id="how-to-claim"
          heading="How to claim"
          css={`
            text-align: center;
            background-color: ${COLOR.WHITE};
            padding-bottom: 0;
          `}
        >
          <TabNavigation
            basepath="/travelpartners/"
            data={[
              {
                id: '#booking',
                label: 'Booking.com'
              },
              {
                id: '#expedia',
                label: 'Expedia'
              },
              {
                id: '#wotif',
                label: 'Wotif'
              }
              // ,
              // {
              //   id: '#hotels',
              //   label: 'Hotels.com'
              // }
            ]}
            defaultActiveTabId="#booking"
            onTabClick={onTabClickHandler}
          />
        </Section>

        <div
          className="row"
          css={{ backgroundColor: COLOR.BLUE_BABY, padding: '20px 10px' }}
        >
          <div className="col-md-7 offset-md-3">
            {activeLinkId === '#booking' && (
              <>
                <ListNumbered
                  separator="none"
                  viewStyle="compact"
                  data={[
                    "<span>Click the 'Book now' button below.</span>",
                    "<span>Click on the 'Unlock deals' button and enter the first 6 digits of your Latitude 28&deg; Global Platinum Mastercard, Latitude GO Mastercard, Latitude GO Platinum Mastercard or Latitude Gem Visa credit card.</span>",
                    "<span>Search an eligible accommodation with an 'Instant Reward' badge and 'Pay Now' option.</span>"
                  ]}
                />
                <Link
                  href="https://www.booking.com/index.html?aid=2381368"
                  button={BUTTON_STYLE.SECONDARY}
                  css={{
                    margin: '20pt auto 0 auto',
                    width: 'fit-content'
                  }}
                  trackId={activeLinkId}
                  trackEventData={{
                    category: 'cta',
                    action: 'book-now'
                  }}
                >
                  Book Now
                </Link>
              </>
            )}
            {activeLinkId === '#expedia' && (
              <>
                <ListNumbered
                  separator="none"
                  viewStyle="compact"
                  data={[
                    "<span>Click the 'Book now' button below.</span>",
                    "<span>Search an eligible 'Pay now' hotel.</span>",
                    "<span>Under the link 'Use a coupon, credit or promotion code', enter the Expedia coupon code and click the 'Apply' button. The coupon code can be found in the Expedia tile in the Offers tab of the Latitude App or the Latitude Service Centre.</span>",
                    '<span>If your booking is eligible, you will see the discount applied. The offer applies to the first room in the multi-room bookings.</span>',
                    '<span>You must use your Latitude 28° Global Platinum Mastercard, Latitude GO Mastercard, Latitude GO Platinum Mastercard or Latitude Gem Visa credit card to pay for the booking.</span>'
                  ]}
                />
                <Link
                  href="https://www.expedia.com.au/lp/b/latitudecard"
                  button={BUTTON_STYLE.SECONDARY}
                  css={{
                    margin: '20pt auto 0 auto',
                    width: 'fit-content'
                  }}
                  trackId={activeLinkId}
                  trackEventData={{
                    category: 'cta',
                    action: 'book-now'
                  }}
                >
                  Book Now
                </Link>
              </>
            )}
            {activeLinkId === '#wotif' && (
              <>
                <ListNumbered
                  separator="none"
                  viewStyle="compact"
                  data={[
                    "<span>Click the 'Book now' button below.</span>",
                    "<span>Search an eligible 'Pay now' hotel.</span>",
                    "<span>Under the link 'Use a coupon, credit or promotion code', enter the Wotif coupon code and click the 'Apply' button. The coupon code can be found in the Wotif tile in the Offers tab of the Latitude App or the Latitude Service Centre.</span>",
                    '<span>If your booking is eligible, you will see the discount applied. The offer applies to the first room in the multi-room bookings.</span>',
                    '<span>You must use your Latitude 28° Global Platinum Mastercard, Latitude GO Mastercard, Latitude GO Platinum Mastercard or Latitude Gem Visa credit card to pay for the booking.</span>'
                  ]}
                />
                <Link
                  href="https://www.wotif.com/?clickref=1011lyjvgQZG&affcid=wotif-AU.network.phg.latitudefinancial.hotels&afflid=1011lyjvgQZG&ref_id=1011lyjvgQZG&my_ad=wotif-AU.network.phg.latitudefinancial.hotels&my_publisher=latitudefinancial"
                  button={BUTTON_STYLE.SECONDARY}
                  css={{
                    margin: '20pt auto 0 auto',
                    width: 'fit-content'
                  }}
                  trackId={activeLinkId}
                  trackEventData={{
                    category: 'cta',
                    action: 'book-now'
                  }}
                >
                  Book Now
                </Link>
              </>
            )}
            {/* {activeLinkId === '#hotels' && (
              <>
                <ListNumbered
                  separator="none"
                  viewStyle="compact"
                  data={[
                    "<span>Click the 'Book now' button below.</span>",
                    "<span>Search an eligible 'Pay now' hotel.</span>",
                    "<span>Under the link 'Use a coupon, credit or promotion code', enter the Hotels.com coupon code and click the 'Apply' button. The coupon code can be found in the Hotels.com tile in the Offers tab of the Latitude App or the Latitude Service Centre.</span>",
                    '<span>If your booking is eligible, you will see the discount applied. The offer applies to the first room in the multi-room bookings.</span>',
                    '<span>You must use your Latitude 28° Global Platinum Mastercard, Latitude GO Mastercard, Latitude GO Platinum Mastercard or Latitude Gem Visa credit card to pay for the booking.</span>'
                  ]}
                />
              </>
            )} */}
          </div>
          <div css={{ width: '100%' }}></div>
        </div>

        <WhyChooseSection
          anchor="why-choose"
          heading="Don't forget all the other amazing features to enjoy!"
        />

        <DontHaveACardSection anchor="dont-have-card" />

        <HaveAQuestionSection
          anchor="have-question"
          heading="Have a question?"
        />

        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne: [
                "<sup id='note-1'>1</sup>&nbsp;Latitude 28&deg; Global Platinum Mastercard cardholders will be eligible for a $10 Latitude Reward every statement period which is redeemable for an e-gift card from participating retailers when they spend $1,000 or more on eligible transactions using their credit card within the statement period. Any purchases made by an additional cardholder will contribute towards the primary cardholder's spend threshold. Eligible transactions are based on the date posted and do not include cash advances, cash equivalent transactions, card fees or charges, credits, refunds, reimbursements, Shopper's Protection premiums, interest and balance transfers into an account. The account must be open and not in default of the credit contract when the Latitude Rewards is issued. The Latitude Rewards will be issued by EonX Services Pty Ltd and will be available for redemption via the Latitude App or Latitude Service Centre within 30 days from the end of the statement period. The primary cardholder has up to 18 months to redeem from the date of issue. View <a href='https://assets.latitudefinancial.com/legals/conditions-of-use/28degrees-au/cou.pdf' target='_blank'>full terms and conditions</a>.",
                "<sup id='note-2'>2</sup>&nbsp;Purchase Protection Insurance is underwritten and issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381 686, Level 19, 2 Park Street, Sydney NSW 2000 (&quot;AIG&quot;) under a group policy of insurance issued to Mastercard. In arranging the insurance Mastercard acts a group purchasing body under ASIC Corporations (Group Purchasing Bodies) Instrument 2018/751. Eligible persons who can access the complimentary Purchase Protection Insurance are the current holders of the relevant Latitude 28&deg; Global Platinum Mastercard which entitles them to these purchase protection insurance benefits. It is important you read your Latitude 28&deg; Global Platinum Mastercard credit card Purchase Protection Insurance Terms and Conditions. Eligible persons can claim as third-party beneficiaries by virtue of the operation of s48 of the Insurance Contracts Act 1984. Conditions and Exclusions apply to this insurance coverage as set out in the group policy and any insurance document provided to the eligible persons and which both may be amended from time to time. Neither Latitude Finance Australia nor Mastercard guarantees this insurance and do not hold an Australian Financial Services Licence. View <a href='https://assets.latitudefinancial.com/legals/28degreescard/purchase-protection-insurance-termsconds.pdf' target='_blank'>full terms and conditions</a>."
              ],
              importantInformationSectionTwo: [
                "<sup id='note-3'>3</sup>&nbsp;E-Commerce Purchase Protection Insurance is underwritten and issued by AIG Australia Limited ABN 93 004 727 753, AFSL 381 686, Level 19, 2 Park Street, Sydney NSW 2000 (&quot;AIG&quot;) under a group policy of insurance issued to Mastercard. In arranging the insurance Mastercard acts a group purchasing body under ASIC Corporations (Group Purchasing Bodies) Instrument 2018/751. Eligible persons who can access the complimentary E-Commerce Purchase Protection Insurance are the current holder of the relevant Latitude 28° Global Platinum Mastercard which entitles them to these e-commerce purchase protection insurance benefits. It is important you read your Latitude 28° Global Platinum Mastercard credit card E-Commerce Purchase Protection Insurance Terms and Conditions. Eligible persons can claim as third-party beneficiaries by virtue of the operation of s48 of the Insurance Contracts Act 1984. Conditions and Exclusions apply to this insurance coverage as set out in the group policy and any insurance document provided to the eligible persons and which both may be amended from time to time. Neither Latitude Finance Australia nor Mastercard guarantees this insurance and do not hold an Australian Financial Services Licence. View <a href='https://assets.latitudefinancial.com/legals/28degreescard/ecommerce-protection-insurance-termscond.pdf' target='_blank'>full terms and conditions</a>.",
                "<sup id='note-4'>4</sup>&nbsp;The Flight Delay Pass gives free access to select airport lounges, alternatively cardholders can use their Flight Delay Pass to offset a dollar amount off the total bill at select dining options. This will vary based on location and affiliated LoungeKey Partners at the time. Please note that not all airports offer Flight Delay Pass and the selected airports may be restricted to certain terminals. Access to and use of the Flight Delay Pass is subject to the Terms of Use which are subject to change. Further details of the list of lounges that cardholders are eligible to visit and the specific conditions applicable to each lounge access (Conditions of Use) may also be accessed in the Terms of Use. Participating lounges and their Conditions of Use are subject to change.",
                'Mastercard and the circles design are registered trademarks of Mastercard International Incorporated.'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
}

export default TravelPartnersPage;
