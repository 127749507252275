/* eslint-disable global-require */
import React from 'react';

import { FeaturesSlider } from '@latitude/features-slider';

const DATA1 = [
  {
    icon: 'icon2-hand-thumbs-up',
    title: 'Pay like a local. Online or overseas.',
    text:
      'Save with no international transaction or currency conversion fees on purchases and great foreign exchange rates set by Mastercard so you get foreign currencies at the same rate we do. T&Cs, card fee and other charges apply.'
  },
  {
    icon: 'icon2-shopping-trolley-pink',
    title: 'Shop and be rewarded with the Latitude Rewards Program',
    text:
      'Enjoy up to $120 worth of Latitude Rewards each year which you can redeem for e-gift cards from participating retailers when you spend $1,000 or more on eligible transactions in every statement period for 12 months<sup>1</sup>.<br /><a href="/credit-cards/28-degrees/latitude-rewards/">Learn more</a>'
  },
  {
    icon: 'icon2-global-shopping',
    title:
      'Shop with confidence with complimentary E-Commerce\u00B2 and Purchase Protection\u00B3 Insurances',
    text:
      'Cover for the damage or theft of purchases and from non-delivery and/or incomplete delivery of online purchases.<br /><a href="/credit-cards/28-degrees/insurances/">Learn more</a>'
  },
  {
    icon: 'icon2-gift-blue',
    title: 'Amazing everyday and travel offers',
    text:
      'Enjoy a range of credit back offers, discounts and deals from big brands at home and abroad with Mastercard Priceless Specials.<br /><a href="https://specials.priceless.com/en-au/cardhome/28%C2%B0_Global_Platinum_Mastercard?issuerId=201810190038&productId=201904150013" rel="noopener noreferrer" target="_blank">Shop now</a>'
  }
];
const DATA2 = [
  {
    icon: 'icon2-globe-blue',
    title: 'Stay in touch with free Global Data Roaming',
    text:
      '3GB free data each calendar year & 15% off any extra data purchased in over 150 countries with Flexiroam. T&Cs apply.<br /><a href="/credit-cards/28-degrees/global-data-roaming/">Learn more</a>'
  },
  {
    icon: 'icon2-travel-alt-pink',
    title: 'Travel with ease with free Flight Delay Pass',
    text:
      'Food, drinks and free Wi-Fi in a selection of airport lounges if your flight is delayed 2+ hours<sup>4</sup>.<br /><a href="/credit-cards/28-degrees/flight-delay-pass/">Learn more</a>'
  },
  {
    icon: 'icon2-low-rate-purple',
    title: 'Great foreign exchange rates set by our partners at Mastercard',
    text: 'So you get foreign currencies at the same rate we do.'
  },
  {
    icon: 'icon2-relationship',
    title: 'Share the love with an additional cardholder at no extra fee',
    text:
      'Share all the features and benefits without needing to open a second account.<br /><a href="/credit-cards/28-degrees/addcard/">Add an additional cardholder</a>'
  }
];

function WhyChooseSection({ anchor, heading }) {
  return (
    <div
      css={`
        && div.lfs-card-icon {
          background-color: #fff;
          width: 100px !important;
          height: auto !important;
          padding: 10px;
          border-radius: 50px;
        }
      `}
    >
      <FeaturesSlider
        id={anchor}
        heading={heading}
        className="why-choose"
        backgroundColor="#F8F8F8"
        data={DATA1}
        css={`
          && {
            padding-bottom: 0;
          }
        `}
      />
      <FeaturesSlider
        className="why-choose"
        backgroundColor="#F8F8F8"
        data={DATA2}
        css={`
          && {
            padding-top: 0;
          }
        `}
      />
    </div>
  );
}

export default WhyChooseSection;
