/* eslint-disable global-require */

import React from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import { Text } from '@latitude/text';
import { ALIGN } from '@/utils/constants';

import imgExpediaOffer from './images/expedia-offer.webp';
import imgWotifOffer from './images/wotif-offer.webp';
import imgBookingCom from './images/booking-com-limited-time.webp';
import imgHotelsCom from './images/hotels-com-new-offer.webp';

function ExpediaWotifSection({ anchor, heading }) {
  return (
    <Section id={anchor} heading={heading}>
      <div
        css={{
          alignContent: 'center',
          justifyContent: 'center',
          margin: '10pt auto 10pt auto',
          padding: '5pt',
          maxWidth: '640px'
        }}
      >
        <Text align={ALIGN.CENTER}>
          We've partnered with the world's best to help you save and get more
          out of every holiday. It's just another benefit of paying with
          Latitude!
        </Text>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '10pt',
          margin: '10pt auto'
        }}
      >
        <OfferItem>
          <img src={imgBookingCom} alt="Booking.com" height="196" />
          <h3>Save up to 10% at Booking.com</h3>
          <p>
            on selected accommodation from 17 September 2024 to 16 December
            2024. Capped at $300 per booking. Offer may be extended or
            withdrawn. Exclusions and T&Cs apply.
          </p>
        </OfferItem>
        <OfferItem>
          <img src={imgExpediaOffer} alt="Expedia" />
          <h3>Enjoy a 10% discount at Expedia</h3>
          <p>
            on selected hotels all day, every day, year-round. Offer may be
            withdrawn. Exclusions and T&Cs apply.
          </p>
        </OfferItem>
        <OfferItem>
          <img src={imgWotifOffer} alt="Wotif" />
          <h3>Enjoy a 10% discount at Wotif</h3>
          <p>
            on selected hotels all day, every day, year-round. Offer may be
            withdrawn. Exclusions and T&Cs apply.
          </p>
        </OfferItem>
        {/* <OfferItem>
          <img src={imgHotelsCom} alt="Hotels.com" height="196" />
          <h3>Enjoy a 6% discount at Hotels.com</h3>
          <p>
            on selected hotels all day, every day, year-round. Offer may be
            withdrawn. Exclusions and T&Cs apply.
          </p>
        </OfferItem> */}
      </div>
    </Section>
  );
}

const OfferItem = styled.div`
  background-color: #ffffff;
  flex-basis: 200px;
  margin: 0;
  padding: 0;
  text-align: center;
  border: 1px solid #ddd;
  border-bottom: 3px solid #888;
  h3 {
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }
  p {
    padding: 5px;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

export default ExpediaWotifSection;
